@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Rubik:wght@400;500;700&display=swap');

.container {
    color: white;
    height: 100vh;
    padding-top: 2%;
}

.box {
    width: 90%;
    height: 80%;
    margin: auto;
    display: flex;
    justify-content: space-between;
}

.innerContainer {
    width: 22%;
    cursor: pointer;
}

.innerContainer img {
    width: 100%;
    height: 40%;
    object-fit: cover;
}

.innerBox1 {
    background-color: #44546a;
    width: 100%;
    height: 32%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Montserrat;
    font-size: 1.5rem;
    word-wrap: break-word;
    transition: box-shadow 0.3s ease;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

.innerBox1:hover {
    transform: scale(1.07);
    font-size: 1.8rem;
    color: black;
}

.innerBox2:hover {
    transform: scale(1.07);
    font-size: 2rem;
    color: black;
}

.innerBox2 {
    background-color: #44546a;
    height: 22%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Montserrat;
    font-size: 1.5rem;
    transition: box-shadow 0.3s ease;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
}

@media only screen and (max-width: 1000px) {
    .container {
        height: auto;
    }

    .box {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 80%;
    }

    .innerContainer {
        width: 100%;
        height: 100%;
        margin-bottom: 2rem;
    }

    .innerBox1 {
        padding: 3rem 1rem;
    }

    .innerBox2 {
        padding: 2rem 1rem;
    }
}