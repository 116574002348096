

.inner::-webkit-scrollbar {
    display: none;
}

.outer {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 1rem;
}

.inner {
    border: 2px solid #829dc1;
    background-color: black;
    height: 35rem;
    width: 73%;
    text-align: left;
    font-size: 1.42rem;
    color: #f1f1f1;
    overflow: scroll;
    padding: 2rem 1.4rem;
    border-radius: 7px;
}

.inner1 {
    height: 7rem;
    width: 100%;
    background-color: #44546a;
    display: flex;
    align-items: center;
    justify-content: center;
    font: bold;
    color: white;
    font-weight: 700;
    font-size: 5rem;
    border-radius: 7px;
}

.inner2 {
    height: 27rem;
    width: 100%;
    margin-top: 1rem;
    background-color: #44546a;
    font-weight: 700;
    font-size: 2.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 7px;
}

.wrapper {
    width: 21%;
    height: 35rem;
    margin-right: 2rem;
}

@media only screen and (max-width: 1000px) {
    .outer {
        margin-top: -3rem;
    }

    .wrapper {
        width: 35%;
        margin-right: 0.8rem;
    }

    .inner2 {
        font-size: 0.9rem;
        width: 100%;
        padding: 1rem;
    }

    .inner {
        width: auto;
        font-size: 0.73rem;
        padding: 1rem;
    }
}