.body {
    color: white;
    width: 100%;
    height: 100vh;
    margin: auto;
    padding-left: 17%;
}

.top {
    display: flex;
    background-color: #44546b;
    height: 24%;
    width: 97%;
    margin: 0.65rem 0;
    border-radius: 7px;
}

.top div {
    width: 85%;
    font-size: 1.03rem;
}

.img {
    background-color: white;
    max-height: 150px;
    width: 15%;
    object-fit: cover;
    border-radius: 0px 7px 7px 0px;
}

.text {
    text-align: left;
    line-height: 1.5;
    padding: 0.3rem 0.5rem;
}

.bottom {
    width: 100%;
    height: 71%;
    display: flex;
}

.left {
    background-color: #44546b;
    width: 21%;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 7px;
}

.heading {
    letter-spacing: 0.15rem !important;
}

.right {
    padding-left: 2rem;
    height: 100%;
    width: 76%;
    text-align: left;
}

.box1,
.box2 {
    background-color: black;
    border-radius: 7px;
    padding: 1rem;
}

.box1 {
    height: 46.5%;
    margin-bottom: 0.8rem;
}

.box2 {
    height: 50%;
}

.box1 p,
.box2 p {
    font-size: 0.93rem;
}

.box1 h3,
.box2 h3,
.para1,
.para3,
.para4 {
    margin-bottom: 0.8rem;
}

@media only screen and (max-width: 1000px) {
    .body {
        padding-left: 0;
        width: 80%;
    }

    .top {
        flex-direction: column-reverse;
        width: 100%;
        height: auto;
    }

    .top div {
        font-size: 0.8rem;
        width: 100%;
    }

    .img {
        width: 100%;
    }

    .left {
        width: 30%;
        position: relative;
    }

    .rotate {
        transform: rotate(270deg);
        transform-origin: left bottom;
        position: absolute;
        top: 55%;
        left: 75%;
        white-space: nowrap;
    }

    .right {
        padding-left: 1rem;
    }

    .box1,
    .box2 {
        height: 48.8%;
    }

    .box1 h3,
    .box2 h3 {
        font-size: 0.8rem;
    }

    .box1 p,
    .box2 p {
        font-size: 0.6rem;
    }
}