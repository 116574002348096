.container{
    display: flex;
    flex-direction: column;
    width: 14%;
    height: 100vh;
    color: wheat;
    position: fixed;
    left: 0;
}

.logo{
    width: 100%;
    height: 28% !important;
    cursor: pointer;
    border-bottom: 1px solid gray;
    border-right: 1px solid gray;
    /* object-fit: cover; */
}

.logo img{
    width: 100%;
    height: 100%;
}

.container a{
    height: 18%;
}

.img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    border-bottom: 1px solid gray;
    border-right: 1px solid gray;
}