

.inner::-webkit-scrollbar {
    display: none;
}

.outer {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
}

.inner {
    border: 2px solid #829dc1;
    height: 35rem;
    width: 73%;
    text-align: left;
    font-size: 1.45rem;
    color: #f1f1f1;
    background-color: black;
    overflow: scroll;
    padding: 5rem 1.4rem;
    border-radius: 7px;
}

.inner1 {
    border: 3px solid #829dc1;
    height: 7rem;
    width: 100%;
    background-color: #44546a;
    display: flex;
    align-items: center;
    justify-content: center;
    font: bold;
    color: white;
    font-weight: 700;
    font-size: 5rem;
    border-radius: 7px;
}

.inner2 {
    border: 3px solid #829dc1;
    height: 27rem;
    width: 100%;
    margin-top: 1rem;
    background-color: #44546a;
    font-weight: 700;
    font-size: 2.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 7px;
}

.wrapper {
    width: 21%;
    height: 35rem;
    margin-right: 2rem;
}

@media only screen and (max-width: 1000px) {
    .outer {
        margin-top: -3rem;
        width: 100%;
    }

    .wrapper {
        width: 45%;
        margin-right: 0.8rem;
    }

    .inner1 {
        width: 100%;
    }

    .inner2 {
        font-size: 0.8rem;
        width: 100%;
        padding: 2.2rem;
        position: relative;
    }

    .inner2 p{
        transform: rotate(270deg);
        transform-origin: left bottom;
        position: absolute;
        top: 85%;
        left: 65%;
        white-space: nowrap;
        font-size: 1.2rem;
    }

    .inner {
        width: auto;
        font-size: 0.97rem;
        padding: 1rem;
    }
}