footer {
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    color: black;
}

.lightUpper {
    color: #777777;
}

.brand-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-bottom: 1.5rem;
}

.brand-container ul {
    list-style-type: none;
    display: flex;
    gap: 1rem;
    
}

.links {
    display: flex;
    gap: 8rem;
    margin-left: 3.5rem;
}

.link {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.alink{
    text-decoration: none;
}

.link h4 {
    /* color: #ffffff; */
    text-transform: uppercase;
}

.lightLink h4 {
    color: #000000;
}

.link ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.lower {
    display: flex;
    justify-content: space-between;
    margin-left: 3.2rem;
    font-weight: 500;
    margin-right: 3rem;
    margin-bottom: 2rem;
}

.span {
    color: #cccccc;
}

.lightLower span {
    color: #777777;
}

@media screen and (min-width: 280px) and (max-width: 1080px) {
    .footer {
        margin: 0;
        gap: 2rem;
        padding: 1rem 2rem;
    }

    .upper {
        flex-direction: column;
        gap: 2rem;
    }

    .links {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }

    .link {
        gap: 1rem;
    }

    .lower {
        flex-direction: column;
        gap: 1rem;
    }
}