.body {
    color: white;
    width: 100%;
    height: 100vh;
    margin: auto;
    padding-left: 17%;
}

.top {
    display: flex;
    background-color: #44546b;
    height: 24%;
    width: 97%;
    margin: 1rem 0;
    border-radius: 7px;
}

.top div{
    width: 85%;
    font-size: 1.03rem;
}

.img {
    background-color: white;
    max-height: 150px;
    width: 15%;
    object-fit: cover;
    border-radius: 0px 7px 7px 0px;
}

.text {
    text-align: left;
    line-height: 1.5;
    border: 3px solid #44546b;
    padding: 0.3rem 0.5rem;
}

.learn {
    height: 100%;
    width: 20%;
    border: 1px solid #44546b;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 1.2rem;
    background-color: black;
    border-radius: 7px;
    border: 3px solid #41719c;
}

.rtext {
    transform: rotate(270deg);
    font-size: 1.3rem;
}

.bottom {
    width: 100%;
    height: 69%;
    display: flex;
}

.rotate {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: space-between;
}

.box {
    margin-top: 0.5rem;
    height: 50%;
}

.box div {
    background-color: black;
    border-radius: 7px;
    margin-bottom: 0.3rem;
}

.left {
    background-color: #44546b;
    width: 21%;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 7px;
}

.left h1{
    letter-spacing: 0.15rem;
}

.right {
    padding-left: 2rem;
    height: 100%;
    width: 76%;
}

@media only screen and (max-width: 1000px) {
    .body{
        padding-left: 0;
        width: 80%;
    }

    .top {
        flex-direction: column-reverse;
        width: 100%;
        height: auto;
    }

    .top div {
        font-size: 0.8rem;
        width: 100%;
    }

    .img {
        width: 100%;
    }

    .left {
        width: 30%;
        position: relative;
    }

    .right{
        padding-left: 0.8rem;
    }

    .rotateit {
        transform: rotate(270deg);
        transform-origin: left bottom;
        position: absolute;
        top: 60%;
        left: 75%;
        white-space: nowrap;
    }

    .rotate{
        height: 40%;
    }

    .rtext{
        font-size: 0.7rem;
    }

    .box div{
        font-size: 0.68rem;
    }

    .learn{
        width: 23%;
        word-wrap: break-word;
        white-space: nowrap;
    }
}