@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Rubik:wght@400;500;700&display=swap');

.main {
    width: 100%;
    padding-left: 14%;
}

.container {
    color: white;
    width: 100%;
    height: 100vh;
    position: relative;
    background-color: black;
    display: flex;
    justify-content: center;
}

.bgimg {
    width: 100%;
    height: 100% !important;
    object-fit: cover;
    opacity: 0.7;
}

.box {
    position: absolute;
    top: 50%;
    width: 80%;
    background-color: rgba(128, 128, 128, 0.6);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 4rem;
}

.blockimg {
    width: 10%;
    margin: 0 1rem;
}

.heading1 {
    font-family: Montserrat;
    word-spacing: 0.5rem;
}

.heading2 {
    font-family: Montserrat;
    word-spacing: 0.5rem;
}

.btn {
    position: absolute;
    top: 75%;
    left: 10%;
    background-color: #ff414b;
    border: none;
    outline: none;
    cursor: pointer;
    color: white;
    padding: 1rem 3rem;
    font-family: Montserrat;
    border-radius: 7px;
    font-weight: 600;
    font-size: 1rem;
}

.btn span {
    transform: rotate(270deg);
    display: inline-block;
}

@media only screen and (max-width: 1000px) {
    .main {
        padding-left: 0;
    }

    .heading1, .heading2 {
        white-space: nowrap;
        font-size: 0.8rem;
        word-spacing: 0.1rem;
    }

    .box {
        padding: 1rem 2rem;
        width: 90%;
    }

    .blockimg {
        width: 20%;
        margin: 0 0.8rem;
    }

    .btn{
        top: 70%;
    }
}