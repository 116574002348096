.boxes {
    display: flex;
    justify-content: space-between;
    width: 85%;
    margin: auto;
    margin-bottom: 3rem;
}

.top {
    height: 10%;
    width: 10rem;
    width: 100%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    font-weight: bolder;
    background-color: #44546a;
    padding: 1rem 0;
    margin-bottom: 2rem;
}

.box {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    border-radius: 10px;
}

.title {
    text-align: left;
    color: #ed563b;
    font-weight: 500;
    margin: 0.4rem 0;
    font-size: 0.9rem;
}

.desc {
    color: #7a7a7a;
    text-align: left;
    line-height: 1.2rem;
}

.name {
    text-align: center;
    font-weight: 600;
    margin: 0.4rem 0;
    font-size: 1.3rem;
}

.social {
    text-align: center;
    margin-top: 1rem;
}

.email {
    color: black;
}

.social i:hover, .email:hover{
    color: #ed563b;
}


@media only screen and (max-width: 1000px) {
    .boxes {
        flex-direction: column;
    }

    .box img {
        width: 100%;
    }

    .box {
        margin-bottom: 2rem;
    }
}