.body {
    color: white;
    width: 100%;
    height: 100vh;
    margin: auto;
    padding-left: 17%;
}

.top {
    display: flex;
    background-color: #44546b;
    height: 24%;
    width: 97%;
    margin: 0.65rem 0;
    border-radius: 7px;
}

.top div {
    width: 85%;
    font-size: 1.03rem;
}

.img {
    background-color: white;
    width: 15%;
    object-fit: cover;
    border-radius: 0px 7px 7px 0px;
}

.text {
    text-align: left;
    line-height: 1.5;
    padding: 0.3rem 0.5rem;
}

.bottom {
    width: 100%;
    height: 63%;
    display: flex;
}

.left {
    background-color: #44546b;
    width: 21%;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 7px;
}

.heading {
    letter-spacing: 0.15rem !important;
}

.right {
    padding-left: 2rem;
    height: 100%;
    width: 79%;
    text-align: left;
    display: flex;
}

.box1,
.box2 {
    height: 100%;
    width: 47%;
    background-color: black;
    border-radius: 7px;
    padding: 1.5rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.box1 {
    margin-right: 1rem;
}

.middle {
    display: flex;
    justify-content: center;
}

.arrow {
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 40px solid black;
}

.dash {
    border: 5px solid black;
    transform: rotate(90deg);
    margin-bottom: 0.25rem;
}

@media only screen and (max-width: 1000px) {
    .body {
        padding-left: 0;
        width: 80%;
    }

    .top {
        flex-direction: column-reverse;
        width: 100%;
        height: auto;
    }

    .top div {
        font-size: 0.8rem;
        width: 100%;
    }

    .img {
        width: 100%;
    }

    .right {
        width: 100%;
        height: auto;
        padding-left: 0;
    }
    
    .box2, .box1 {
        height: auto;
        padding-left: 0.8rem;
        font-size: 0.85rem;
    }

    .bottom {
        height: auto;
    }

    .box1 p, .box2 p{
        margin-bottom: 0.5rem;
    }

    .minus{
        color: black;
    }
}