.outer {
    height: auto;
    margin-bottom: 3rem;
}

.marquee {
    color: white;
    width: 100%;
    margin: 0.8rem 0;
}

.heading {
    font-size: 2rem;
    background-clip: text;
    background: -webkit-linear-gradient(#eee, #333);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.top {
    height: 10%;
    width: 10rem;
    width: 100%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    font-weight: bolder;
    background-color: #44546a;
    padding: 1rem 0;
}

.rightbarFollowings {
    text-align: left;
    background-color: #000000;
    background-color: #44546a;
    color: white;
    border-radius: 20px;
    margin-top: 20px;
    font-size: 1.15rem;
    margin-left: 1.5rem;
    border: 5px solid #829dc1;
    height: 100%;
    padding: 1.5rem;
    text-align: justify;
    text-justify: inter-word;
}

.wrapper {
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
    margin-bottom: 4rem;
}

@media only screen and (max-width: 1000px) {
    .rightbarFollowings {
        font-size: 0.9rem;
        width: 80%;
        margin-left: 0;
    }

    .wrapper {
        flex-direction: column;
    }
}